.info h1 {
  color: white;
}

.info h2 {
  color: white;
}

.info body {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;

  padding-left: 40px;
  padding-right: 40px;
  color: white;

  justify-content: center;
  display: flex;
  flex-direction: column;
}

html, body {
  height: 100%;
}

.info p {
  text-align: justify;
  font-size: 18px;
  color: white;
}

/*
.footer img {
  width: 325px;
  height: 325px;
  object-fit: cover;
  border: 2px solid rgba(255, 255, 255, 1);
  z-index: 2;
}*/

/* Set max-width max-height in the event that I don't feel like doing 325px squares */
.preview  {
  max-width: 325px;
  max-height: 325px;
  border: 2px solid rgba(255, 255, 255, 1);
  margin: 4px;
}

.info {
  flex: 1 0 auto;
  padding: 20px;
  padding-bottom: 10%;
}

.infoContents {
  z-index: 2;
}

.footer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  margin-bottom: 10px;
}

/* unvisited link */
.info a:link {
  color: #b9f588;
  text-decoration: none;
}

/* visited link */
.info a:visited {
  color: #b9f588;
  text-decoration: none;
 }


/* mouse over link */
.info a:hover {
  color: hotpink;
  text-decoration: none;
}

/* selected link */
.info a:active {
  color: #a669c9;
  text-decoration: none;
}

@media only screen and (max-width: 700px)  {
  .preview  {
    max-width: 125px;
    max-height: 125px;
    border: 1px solid rgba(255, 255, 255, 1);
    margin: 2px;
  }

  .info {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    
    padding: 0 5px 5px 5px;
    color: white;

    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .info h1 {
    font-size: 18px;
  }

  .info h2 {
    font-size: 16px;
  }

  .info p {
    font-size: 14px;
    padding: 0 5px 5px 5px;
  }
}
